import { SingleMove, Towers, IllegalSingleMove, IllegalMove } from "../types";

export const findLargestRing: (towers: Towers) => number = (towers: Towers) => {
  if (towers.towers.length === 0) {
    return 0;
  }

  const headMax = Math.max(...towers.towers[0].rings);

  return Math.max(
    headMax,
    findLargestRing({
      towers: towers.towers.slice(1),
    })
  );
};

export const copyTowers: (toCopy: Towers) => Towers = (toCopy: Towers) => ({
  towers: toCopy.towers.map((t) => ({
    rings: [...t.rings],
  })),
});

export const makeSingleMove = (towers: Towers, move: SingleMove) => {
  const towerCount = towers.towers.length;

  // Check for valid tower indecies
  if (
    move.fromIndex >= towerCount ||
    move.fromIndex < 0 ||
    move.toIndex >= towerCount ||
    move.toIndex < 0
  ) {
    throw new IllegalSingleMove(move, `Invalid index ${towerCount} towers.`);
  }

  const fromTower = towers.towers[move.fromIndex];
  const toTower = towers.towers[move.toIndex];

  const ringToMove = fromTower.rings[fromTower.rings.length - 1];
  const topToRing = toTower.rings[toTower.rings.length - 1];

  // Check that right is smaller than the top ring
  if (ringToMove > topToRing) {
    throw new IllegalSingleMove(
      move,
      `Ring ${ringToMove} is too big for tower ${move.toIndex}.`
    );
  }

  // Perform move
  fromTower.rings.pop();
  toTower.rings.push(ringToMove);
};

const findOtherStack = (stackCount: number, knownIndicies: number[]) => {
  for (let i = 0; i < stackCount; i++) {
    if (!knownIndicies.includes(i)) {
      return i;
    }
  }

  throw new Error("Error finding other index");
};

export const getMoveStackMoves = (
  towers: Towers,
  fromIndex: number,
  toIndex: number
) =>
  getMoveStackMovesHelper(
    towers,
    fromIndex,
    toIndex,
    towers.towers[fromIndex]?.rings?.length
  );

const getMoveStackMovesHelper: (
  towers: Towers,
  fromIndex: number,
  toIndex: number,
  ringsToMove: number
) => SingleMove[] = (
  towers: Towers,
  fromIndex: number,
  toIndex: number,
  ringsToMove: number
) => {
  const towerCount = towers.towers.length;

  if (
    fromIndex >= towerCount ||
    fromIndex < 0 ||
    toIndex >= towerCount ||
    toIndex < 0
  ) {
    throw new IllegalMove(`Invalid index ${towerCount} towers.`);
  } else if (towerCount !== 3) {
    throw new IllegalMove(`Tower algorithm only works for 3 towers.`);
  }

  const fromRings = towers.towers[fromIndex].rings;

  if (fromRings.length === 0) {
    // Empty stack, do nothing
    return [];
  }

  if (Math.min(fromRings.length, ringsToMove) === 1) {
    // One ring, make a single move
    return [
      {
        fromIndex,
        toIndex,
      },
    ] as SingleMove[];
  }

  // Multiple rings, make recursive calls
  const otherIndex = findOtherStack(towerCount, [toIndex, fromIndex]);

  const topRings = towers.towers[fromIndex].rings.slice(1);

  const firstTowers = copyTowers(towers);
  firstTowers.towers[fromIndex].rings = [...topRings];

  const firstMoves = getMoveStackMovesHelper(
    firstTowers,
    fromIndex,
    otherIndex,
    ringsToMove
  );

  // Move bottom ring to "to" stack
  const middleMove = {
    fromIndex,
    toIndex,
  } as SingleMove;

  const lastTowers = copyTowers(towers);
  lastTowers.towers[otherIndex].rings = [...topRings];
  // Move "rest" of stack back on top of bottom ring
  const lastMoves = getMoveStackMovesHelper(
    lastTowers,
    otherIndex,
    toIndex,
    ringsToMove
  );

  return [...firstMoves, middleMove, ...lastMoves];
};

export const moveStack = (
  towers: Towers,
  fromIndex: number,
  toIndex: number
) => {
  const moves = getMoveStackMoves(towers, fromIndex, toIndex);

  moves.forEach((move) => {
    makeSingleMove(towers, {
      fromIndex: move.fromIndex,
      toIndex: move.toIndex,
    });
  });
};

export const getAllTowerPositions = (
  towers: Towers,
  fromIndex: number,
  toIndex: number
) => {
  const mainTowers = copyTowers(towers);
  const allPositions = [copyTowers(towers)];

  getMoveStackMoves(towers, fromIndex, toIndex).forEach((move) => {
    makeSingleMove(mainTowers, move);
    allPositions.push(copyTowers(mainTowers));
  });

  return allPositions;
};
