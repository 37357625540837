import { SolanaRenderConfig } from "./types";

const SOLANA_COLORS = {
  surgeGreen: "#00FFA3",
  oceanBlue: "#03E1FF",
  purpleDino: "#DC1FFF",
  black: "#101921",
};

export const webConfig: SolanaRenderConfig = {
  CANVAS_SIZE: {
    widthHeightRatio: 2.0,
  },
  SOLANA_COLORS,
  BACKGROUND_COLOR: SOLANA_COLORS.black,
  STROKE_WIDTH: 0,
  BOARD: {
    borderColor: "#51312D",
    color: "#707070",
    widthPct: 0.9,
    heightPct: 0.19,
    bottomSpacePct: 0.032,
  },
  PEGS: {
    color: "#707070",
    widthPct: 0.03,
    heightPct: 0.7,
  },
  RINGS: {
    color: SOLANA_COLORS.black,
    borderColor: "black",
    borderWidth: 2,
    ringHeight: 0.075,
    gapHeightRatio: 0.263,
    minWidthPct: 0.08,
    bufferWidthPct: 0.025,
    borderRadius: 5,
    polyXShiftPct: 0.018,
  },
  TEXT: {
    text: "SOLANA",
    color: "white",
    heightPct: 0.22,
    spaces: 5,
    yOffsetPct: 0.06,
    font: "solana",
  },
};
