export const nftAddresses: Record<number, string> = {
  0: "AfwsMpLc13iGLtC5PZNtfGSvtuAs2rDsPFn43ufvVjNU",
  1: "MrGJkSXXpCCEzzpjFdpqJGKSh8w5catKY5jHHftZYKc",
  2: "CGzjy7buqfKwdk2Ed4MhFsiQ7MLZ1fR8Zrw1y15izEFm",
  3: "Wc8YdV1cK6bFsM57JTHwMzHEXX3Kau7ZCpPx1A9y49F",
  4: "34p3od2hheotGP3zykrsDhdBXjW76NXZ2GM9R3JdvBig",
  5: "7CgiGkQxk9yMhx6vq85ghrwingD6M7uGiZmEFUa8S2gb",
  6: "7XffheHuxfnAH9CfMh4pLhuguwFRCUX4sHa68mfNcUSX",
  7: "BphKN2FCdXJjj7XVJd8zS8Z5BpdBitYJhm8vdfSwih7w",
  8: "4Py3b5tVzA3SrvukjxMh1qvatVopwcQRC7JuctAAe2Py",
  9: "EvKMK6nUiuAtr2fHpPFU7mXPJ1yjnX7JHiKy83YLn7SK",
  10: "BpFMM92TGin5LpQKSdrn4AqTTEgzmF4S3rrMjyDQdSaA",
  11: "8ewPZpRtnPGop1Me3fi2QKKABEzYMbtJ4MVFzpiFDV1t",
  12: "8vVUSbaPpvuC7NdkfX4vUdvMbSbryku1pf3fJcchD8zp",
  13: "Xt3xFiNC3HDzd2RTFbHR9UbKot6FGyixFpZ5ADYMvBm",
  14: "4on8r9Rmkg16h6xE7ec7xNknrhxjASpUyJLEEnxffrjd",
  15: "HrE8kKPbSok5SmFykbQqC5h8pvnv3vMbdCdZpNRgrSsy",
  16: "6TUw95Nn91S9DN1sas8WCk8T6uyTGVyiBaBQP7jiaSPE",
  17: "8sUmyYFxzTjxA827Kp533Bw5HpVXRhvhRx52Lqv7Rwgw",
  18: "3VwZfEs3D5uUWHqqENcMurV7EwefYLKHRACwBcNyH1DQ",
  19: "4mJ7MBgYdJC2PDTDUDf5LrtMg7DsV8xmKQX8d48spNHA",
  20: "7DdbJewHpQzfebekYAYE9ZoEZM2CSvWUdq6Mq8qUKtt5",
  21: "GigrX1nG3tduHAK7bxqWzDY2NbSM75uspgaJTMXp9QcJ",
  22: "DsGsB5V6vktudkis5rngB9Ytu8W88egzx3r1Audm669A",
  23: "7pkLEWSUDUj6NixJF2Dpc1K7vQR69B6gZTvZqF9Z5oEt",
  24: "BTzHBKJPtSyViCjVP7UJKnBp7jiw3zwnNhBA6Q9kaXwV",
  25: "BWbGr8yHhZE3aM4LcqzTwRV3un3zAvs8vjaXfkQATCne",
  26: "89FbrvAzeFmZprYcdyjfhQTZYzuCrseAhC59DqxzNHf",
  27: "2f6w5WHf3nAXjzwMgD7tbn8zNrqByW5RccLGgN6frBx3",
  28: "5X5J3fV7f7qpomWrKde15UFZG5w7pmoGDVS4kaZ12P3D",
  29: "7KgPe8hbrD6kYFiEykeXTpg8ADcpcZPRChqwese2P8tj",
  30: "HhcKfjC4tZ8KHCuio4LmAMgnosDXBuNpsU9RC4vuoSUq",
  31: "8KH7DKnPd4RqQLios6Q2om7G2JBj9AZYvH3GF4WDYURC",
  32: "6fr2FsEUvZSEzemtkc1K9ji7FzWSK1cKLQ3GoujHL98U",
  33: "HrNQvmkAD9AjMEMUG2VZp2YZUJzcGRa6Dq9Q7ShmzEfi",
  34: "GAhAEkKtUGyymEMGTZz65Adb6szcx2TMFtCfR16yZhqb",
  35: "8UWEXqA8VHVnvLNwhb6msAeqJpdqAfETtUdCFwamrkvJ",
  36: "48Q3S5nqKT18aynwf6Rtqn4S4YSxGZUvNRhNRcVBArRm",
  37: "F6ApbWQfyMFKxNB5yAddQJw1ZKD1DUTQA4bWyVv9xgpn",
  38: "AtYW3d23KqVPBivqLCXzTo8dpNaWqRN1Tf9PBxtY5sHV",
  39: "63bGqkTVZrMUDExnhjGuw5qT7gFpuDV6fTJtDDLijYu7",
  40: "9m2ea423DrdEuc3or8bidg5ALLRFQTnJbN2SGF33JtwR",
  41: "2zPiZGojdS8srqt1Lcsc8ScFQTevnuYyqrGbmmVV8vFk",
  42: "6ANjBx2sfF4hG4r3nTNdzEcLdvPDK4BCKUqb1AL8hQag",
  43: "CJZYs6JGZQrTJfRXCYc1JmWCuce2hfWmsKimNfxbusfC",
  44: "5dxUuP5FNtfPPq78rVyhgQJqVqXBACwA6r3FHyFyJFsY",
  45: "5b6wUPohMwK4g5RoF3nSbjD8MynGjHMmBhw5ywbsYLB1",
  46: "HhwmMvo5qavXFeraC7cgVqYhmjmDKhns3jK4oqv1jcKp",
  47: "BQqHFSgGLb6ozqdhbdSWm9enay3gNnSPR5UePGjYZGdN",
  48: "CtRwKSJfSErGQePyXqWQmyf1hjSmADDsPDYCZaajYgM8",
  49: "64FxCGsPLtYh8mkiDi4qxKEYqGyHQGX4ePpUdMJMJe78",
  50: "FitnmCQHPj7yXwpSU7Zf2xUTUbzcrAD7TVc5XS8BRmMx",
  51: "GXY6fJsFPE838ZWdeK64wwuSdho3RYPmPnfriGQGNxHY",
  52: "FUQwgXwKSYQfAmMrpLcxGECa9kfkUP1xhuQCaGqCKWL5",
  53: "AtnHfaJqP82rriAJzcxZTaiWYYMiSWXts8wHcKqyrerU",
  54: "GPLcd6cFfveK9r3fXVwDNFenkZb9YzQMUKQcgAUCQLs5",
  55: "58RASjXCMURa3J6XDAL7MxpoRFMbq7xnnSuPR4TrLnCK",
  56: "7GF2h8Fp5tGDGotmbPThcpWFdi3V8NwoSwP3qQsMVcyM",
  57: "7B9MCiKTQvkszX8CapWJ76HLuWoDQriGYyBtCEd9htAE",
  58: "8AaMnKUvH9rvZpVCmkfdtyWzhdivfK1crumxzhrKkvac",
  59: "HZFuncThoaDe91ibahxYmPXZTCu6HygF9w5Y5AK65RdS",
  60: "BjNYKwvkee6ST9Vtfix91iVeZQDE6CciwsZCAPQxTrPY",
  61: "8gkKXJfxkyMwZyjkrtFn4ssvVSjYN5m3ibCJaELPi4cY",
  62: "AUwEqLGYazoaNtt1tweN4MJJqHeiBQiAZj3ryKC3An5s",
  63: "8kWUtVfpUUgpqv3eZQanRidZapef4Fh2dHozHTMHLbn8",
  64: "xUX5E24D7FzjvAZRKQ6xVU55BgQHot2awrtazw6rtyv",
  65: "J2FjL4RXWYxvWprapefB7cokJSvKACEL6xoahKxu6orx",
  66: "FQfLAr4GopcgZ4bgTDohXk8iP6MJmLDtbgt8RDoe39DC",
  67: "HXgE3nDoVuisgKY4kgM1nq7xubH8D28kmLpuvnFJQgva",
  68: "2f3RSBAssWX2D6kU7kzBAP9sozaTA63CDDgoHjQVgP29",
  69: "7NDPg8vYGYwXo88gjpxA2PPvEBVaFR1oVMJTifQGe32X",
  70: "QvMGzNaWxMDcKTTpCufLnpMJLaxWyh5nfuPJCJPAizZ",
  71: "9nLi7JhyFCkj6nJGxvp2HHNqffC8KmMtfdVfy7UxGtpj",
  72: "B7vjy8dkifTHiV98mKb6NiRhKKzpjAUFz6FmckRxDDuQ",
  73: "FJ3AkPFjGzTrME4y54psTRKVqpbduJTJbqiCXbJpc58E",
  74: "Hbx3SWt8YugX24fnVHFWAFbuLiYmSDyxb6TbN9Fu6ZRY",
  75: "AHd5f1QTG8m5iu2BdJ4T2frnrYXNmaMsE1QUV6kAfmhv",
  76: "B1wULJFvSpe3aq2N3M8C6oyr5nGbAeSAzQGSfFQupEBb",
  77: "5oKR4541QSSS5NmLCmaQz1FGnTdtGrCnwMymXb9WHnfg",
  78: "7FmxvyRSvqX7rYohquoHdgxTGRxnhx8JgBJraxtVUxoC",
  79: "7g4ZoPLdRQQYUdbMReSCEdf7jFZQkeJSoNsLDdcaY9Dk",
  80: "HeU92ybAAdaVnABqu3cPCn6UzCVHEYFmsEasLFKfN72V",
  81: "6wwjGPrpcsv5x7crxtQpgUfJbc7QebbDTaCoeTeW9xSX",
  82: "DsHEbqXkT6sif43eQvLvcJAWSoK4nuXEdKdvLWynJbaD",
  83: "3ipbwpRaecqkXgoke39gfp7gXrZstba3Swt3EexRRvtr",
  84: "GasmAdm9UigXT3f6TatMocHvGR5NbBGAzbXZf1Zdpc8o",
  85: "HnAVPudpV6EaY1VR8hW6hRhHajVBtEuf7kQaweHnegr1",
  86: "84hihjjBMQmLwA8MtfCXoEyRMguUdP3RLnETyToihcmu",
  87: "37GquQ7Bk6NhK5bzkoqk6DeQDGFuiPMPVcDXfTekshNv",
  88: "9eF2ZPcoXA9LCzYKnFzykujmHRBQPTeY4Re8C2eqHsja",
  89: "2PmVWRfZHwRauocs2s92v4j45LSMN6jAqPhXDPvbw4vA",
  90: "81PUqznbZPqzJ9Y4bGnwQCjcXNiqBHBFsZAkwW3RgA4z",
  91: "8PbhoSZjio8sKkHViGZ6E8FfeH4mNdHYztNqucoyYHPG",
  92: "DLVwu5y2i5nJtqtB15XqMJMM2htE5kYLy3JZrSMAiCgh",
  93: "4pX3ayyheA71Gvt3FCjGquqEmSi46rM7ZJ4n4MUr1D8b",
  94: "BeMf94fjysNDPzhsP1eQ8wJRoNV3BrPbjNMDHnX8uknf",
  95: "7ASA5BBsztPn4D5DeSaaFxzvvyRxxhdfcKGiPPQVBiTw",
  96: "23hJpi4kvke8ZyQB3bJpz2z45oRe5P4vfXgBanyWe5Ta",
  97: "tjXWtiBHX522goQFmpLPFFdmGWLUoktSbHMd8mTCPEB",
  98: "8if8YGDRymnoHxatRnU5hNNQZnvzwoYNoSJyLGCn3PT6",
  99: "3rcWNk5bezSWZugvv61ishfbmg1Q488oswN26AihuwGt",
  100: "G3F9pBSiD71FFqmg4NMzENj9ArqhC5mTAf8avrJUHRch",
  101: "Fkr7cU7AaFtPEjfugwUfb7B4jNA7koaqB7BitjbyFHeH",
  102: "6mH5Xx6WR9hpe1gfjrfXYy5NoEPk8L1k9v4cSr6wNrxJ",
  103: "BpofkSr9SEJ5qSxJjHPwtcx9bonZ4z5aFSf61of8VSj3",
  104: "HEmH9LEQFPAcGb6ira4rmKtYEuyM2p2YVx896WuV3epJ",
  105: "ADwq21vNHMEVx4h8sSx6WqibSafvNzbM6n9N3E6mdtLs",
  106: "EPnuAFjDQtUdejePby6HAunqaxjYYwLtcseoGyBMdRXm",
  107: "2QYwUgxF9m9jP12xvdSeepzPBbLTc7c8x3zcY9Zu7JqC",
  108: "JB1NbDQM9ifXT1XJuyZyE8SSg5KKBUzzv6u72JREhrvY",
  109: "FZUM38NWxkXEyam1vJ1dtFUfmhYM3r9X8s2xfFqBQjhU",
  110: "EbPorHCXU18445Bo28abSrd5VYYukjxG7TFsVNtVubs6",
  111: "XPP63coVY5CnaKgXrC7NtjaCfSDnDvnyp8F5dzuXnXp",
  112: "EW4RBxJrqDCCajAb3EqMpfdQq55xtNxe5S1bzWN64VQK",
  113: "91jyRuq3JJcE6jCZ2kn2Stqh5NWZbGdCuaKzAwmcFuME",
  114: "BADLd2JEmEMmxuDyqC3VCKQ1pDCNMr9Kr5sfsBrVGxeZ",
  115: "7YWc4k8TpQDNdgzn2cWjy7ixte2qeF5kNS7R2ABH8uwp",
  116: "GGxRxHd768HMAQoXTFu5BeLKnxUrmJgogsTjx3rguA1v",
  117: "D61rSwQm4UPMJSrFdSdw8sxEXbrYyHrSeRgdCKHp7xgN",
  118: "8RqSWEPfYtWkMxaBzmaKDT2UVeHGcUWYZ8yDwi3sgGoj",
  119: "4ArpaLvSPSxCnWQuULeBkxCwgQP278zpiz2f8LXeQnzQ",
  120: "DektU7snHBr7ca1rYR7boqFApKeGenB3sdWPUd3RVe5N",
  121: "4iwfRUzq375vm939sFroYWNM7axoM3tdF46tTTcALrj3",
  122: "FdJPeCGj5mXKQzKfWjmDfhdQ9VZuNjTdGpJtxfurDXqQ",
  123: "HALcGPMKfJxwbb5AGf9tCrgFzt2ppBgT84BZ25HsQSkr",
  124: "mFhKkAe1CVTpkTUQUd99TyfnRsvqsY6PqCrjYBnJ6uf",
  125: "B85MgcDNYBJYg6utk8v4WWqDJdUZKV62urcTMu52qgnN",
  126: "AJ79j5whfNvsn6uQYVCPxj82MykKKHaHmQbZJ58MFxdf",
  127: "Cowa7819LQEmyn9A9KcBgkF6EufRUKcmZtiVHfLyhfTh",
};
