import { useEffect } from "react";
import { useMutation } from "react-query";
import { getTowerOfSolanaNfts } from "../services/metaplex";
import { NFT } from "../services/metaplex/types";
import { useAppData } from "./useAppData";

/**
 * Gets NFT data when public key is present. Sort of a listener.
 * @returns
 */
export const useTosNfts = () => {
  const { publicKey, tosNfts, setTosNfts } = useAppData();

  const { mutate, isLoading, isError } = useMutation(
    (pubKey: string) => getTowerOfSolanaNfts(pubKey),
    {
      onSuccess: (nfts: NFT[]) => {
        setTosNfts(nfts);
      },
    }
  );

  useEffect(() => {
    if (publicKey && !tosNfts) {
      mutate(publicKey);
    }
  }, [publicKey, tosNfts, mutate]);

  return {
    tosNfts,
    isLoading,
    isError,
  };
};
