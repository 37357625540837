import { getSolseaLink } from "../../utils/solsea";

interface TosNftCardProps {
  name: string;
  image: string;
  mintAddr: string;
}

export const TosNftCard: React.FC<TosNftCardProps> = ({
  name,
  image,
  mintAddr,
}) => {
  return (
    <a href={getSolseaLink(mintAddr)} target="_blank" rel="noreferrer">
      <span className="tos-nft-card">
        <span className="tos-nft-card-label">{name}</span>
        <img src={image} alt={name} />
      </span>
    </a>
  );
};
