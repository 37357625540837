import { useMutation } from "react-query";
import { useAppData } from "./useAppData";
import { connectToPhantom } from "../services/phantom";

export const usePhantom = () => {
  const { setPublicKey } = useAppData();

  const { mutate, data, isLoading, isError, isSuccess } = useMutation(
    connectToPhantom,
    {
      onSuccess: (pk: string) => {
        setPublicKey(pk);
      },
    }
  );

  return {
    connect: mutate,
    isConnected: isSuccess,
    isError,
    isConnecting: isLoading,
    walletData: { publicKey: data },
  };
};
