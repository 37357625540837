import { TowerRenderer, Towers } from "../../types";
import { findLargestRing } from "../../utils/towers";
import { roundedPolygon, Point } from "../../utils/rounded-polygon";
import { SolanaRenderConfig } from "./types";

export class SolanaRenderer implements TowerRenderer {
  config: SolanaRenderConfig;
  renderWidth: number; // Should be set before each render

  constructor(config: SolanaRenderConfig) {
    this.config = config;
    this.renderWidth = -1;

    // TODO
    // registerFont(__dirname + "../../../../assets/solana-font.ttf", {
    //   family: this.config.TEXT.font,
    // });
  }

  getBottomGap = () => this.getHeight() * this.config.BOARD.bottomSpacePct;
  getBoardWidth = () => this.getWidth() * this.config.BOARD.widthPct;
  getBoardHeight = () => this.getHeight() * this.config.BOARD.heightPct;
  getBoardXStart = () =>
    this.getWidth() * ((1 - this.config.BOARD.widthPct) / 2);
  getBoardYStart = () =>
    this.getHeight() - this.getBoardHeight() - this.getBottomGap();
  getPegHeight = () => this.config.PEGS.heightPct * this.getHeight();

  drawBaseBoard = (ctx: CanvasRenderingContext2D) => {
    const boardRect = {
      x: this.getBoardXStart(),
      y: this.getBoardYStart(),
      w: this.getBoardWidth(),
      h: this.getBoardHeight(),
    };
    ctx.fillStyle = this.config.BOARD.color;
    ctx.strokeStyle = this.config.BOARD.borderColor;
    ctx.lineWidth = this.config.STROKE_WIDTH;
    ctx.fillRect(boardRect.x, boardRect.y, boardRect.w, boardRect.h);
  };

  writeBoardText = (ctx: CanvasRenderingContext2D) => {
    const textHeight = ctx.canvas.height * this.config.TEXT.heightPct;

    const yOffsetPct = this.config.TEXT.yOffsetPct * ctx.canvas.height;

    const yStart =
      ctx.canvas.height -
      (this.getBoardHeight() - textHeight) / 2 -
      yOffsetPct -
      this.getBottomGap();
    const xStart = this.getBoardXStart() + this.getBoardWidth() / 2;

    const text = this.config.TEXT.text
      .split("")
      .join(" ".repeat(this.config.TEXT.spaces));

    ctx.font = textHeight.toFixed(2) + "px " + this.config.TEXT.font;
    ctx.fillStyle = this.config.TEXT.color;
    ctx.textAlign = "center";
    ctx.fillText(text, xStart, yStart);
  };

  drawBackground = (ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = this.config.BACKGROUND_COLOR;
    ctx.fillRect(0, 0, this.getWidth(), this.getHeight());
  };

  drawPegs = (t: Towers, ctx: CanvasRenderingContext2D) => {
    const boardWidth = this.getBoardWidth();
    const boardXStart = this.getBoardXStart();
    const boardYStart = this.getBoardYStart();
    const pegHeight = this.getPegHeight();
    const pegWidth = this.config.PEGS.widthPct * this.getWidth();
    const pegDistance = boardWidth / (t.towers.length * 2);
    const pegYStart = boardYStart - pegHeight - this.config.STROKE_WIDTH / 2;
    const maxWidth = this.config.PEGS.widthPct * this.getWidth();

    ctx.fillStyle = this.config.PEGS.color;

    for (let i = 1; i <= t.towers.length; i++) {
      const pegXPos = boardXStart + pegDistance * (i * 2 - 1) - pegWidth / 2;

      // Adding +1 to some Y distances to get rid of single pixel gap
      // that sometimes shows up

      // Draw peg
      ctx.fillRect(pegXPos, pegYStart, maxWidth, pegHeight + 1);

      // Draw rounded top
      ctx.beginPath();
      ctx.arc(
        pegXPos + maxWidth / 2,
        pegYStart + 1,
        maxWidth / 2,
        Math.PI,
        Math.PI * 2
      );
      ctx.fill();
      ctx.closePath();
    }
  };

  drawRings = (t: Towers, ctx: CanvasRenderingContext2D) => {
    const largestRing = findLargestRing(t);

    const bottomRingEdge = this.getBoardYStart() - this.config.STROKE_WIDTH / 2;
    const buffer = this.config.RINGS.bufferWidthPct * this.getWidth();

    const ringHeight = this.config.RINGS.ringHeight * this.getHeight();
    const biggestRingWidth =
      this.getBoardWidth() / t.towers.length - buffer * 2;
    const largestRingYPos = bottomRingEdge - ringHeight;
    const boardXStart = this.getBoardXStart();
    const smallestRingWidth = this.config.RINGS.minWidthPct * this.getWidth();
    const gapSize =
      this.getHeight() *
      this.config.RINGS.gapHeightRatio *
      this.config.RINGS.ringHeight;
    const xShift = this.config.RINGS.polyXShiftPct * this.getWidth();

    ctx.fillStyle = this.config.RINGS.color;
    ctx.strokeStyle = this.config.RINGS.borderColor;
    ctx.lineWidth = this.config.RINGS.borderWidth;

    for (let towerIndex = 0; towerIndex < t.towers.length; towerIndex++) {
      const tower = t.towers[towerIndex];

      const largestRingXPos =
        boardXStart +
        towerIndex * biggestRingWidth +
        buffer * (towerIndex * 2 + 1);

      const grad = ctx.createLinearGradient(
        largestRingXPos,
        0,
        largestRingXPos + biggestRingWidth,
        -60
      );
      grad.addColorStop(0, this.config.SOLANA_COLORS.purpleDino);
      grad.addColorStop(0.5, this.config.SOLANA_COLORS.oceanBlue);
      grad.addColorStop(1, this.config.SOLANA_COLORS.surgeGreen);
      ctx.fillStyle = grad;
      ctx.strokeStyle = this.config.RINGS.borderColor;
      ctx.lineWidth = this.config.RINGS.borderWidth;

      for (let ringIndex = 0; ringIndex < tower.rings.length; ringIndex++) {
        const ringSize = tower.rings[ringIndex];

        const ringWidth =
          biggestRingWidth -
          ((biggestRingWidth - smallestRingWidth) / largestRing) *
            (largestRing - ringSize);

        const ringXPos = largestRingXPos + (biggestRingWidth - ringWidth) / 2;
        const ringYPos =
          largestRingYPos - ringIndex * (ringHeight + gapSize) - gapSize;

        const shiftDir = (largestRing - ringIndex) % 2 === 1 ? -1 : 1;
        this.drawRing(
          ctx,
          ringXPos,
          ringYPos,
          ringWidth,
          ringHeight,
          xShift * shiftDir
        );
      }
    }
  };

  drawRing = (
    ctx: CanvasRenderingContext2D,
    ringXPos: number,
    ringYPos: number,
    ringWidth: number,
    ringHeight: number,
    xShift: number
  ) => {
    const br = this.config.RINGS.borderRadius;

    let xPos = ringXPos + xShift;

    const points: Point[] = [];

    ctx.moveTo(xPos, ringYPos);

    points.push({
      x: xPos + ringWidth,
      y: ringYPos,
    });

    // Reverse shift for bottom
    xPos = ringXPos - xShift;

    points.push({
      x: xPos + ringWidth,
      y: ringYPos + ringHeight,
    });

    points.push({
      x: xPos,
      y: ringYPos + ringHeight,
    });

    // Un-reverse shift to finish
    xPos = ringXPos + xShift;

    points.push({
      x: xPos,
      y: ringYPos,
    });

    ctx.beginPath();

    roundedPolygon(ctx, points, br);

    ctx.closePath();
    ctx.fill();
  };

  drawTowers = (
    t: Towers,
    canvasContext: CanvasRenderingContext2D,
    pixelWidth: number
  ) => {
    this.renderWidth = pixelWidth;

    this.drawBackground(canvasContext);

    this.drawBaseBoard(canvasContext);

    this.writeBoardText(canvasContext);

    this.drawPegs(t, canvasContext);

    this.drawRings(t, canvasContext);
  };

  getWidth = (renderWidth: number = this.renderWidth) => renderWidth;
  getHeight = (renderWidth: number = this.renderWidth) =>
    renderWidth / this.config.CANVAS_SIZE.widthHeightRatio;
}
