import React, { createContext, useContext, useState } from "react";
import { NFT } from "../services/metaplex/types";

interface AppState {
  publicKey?: string;
  tosNfts?: NFT[];
  setPublicKey: (pk: string) => void;
  setTosNfts: (nfts: NFT[]) => void;
}

const defaultAppState: AppState = {
  setPublicKey: (_: string) => {},
  setTosNfts: (_: NFT[]) => {},
};

const AppContext = createContext<AppState>(defaultAppState);

export const AppStateProvider: React.FC = (props) => {
  const [appState, setAppState] = useState<AppState>(defaultAppState);

  const setPublicKey = (pk: string) =>
    setAppState({
      ...appState,
      publicKey: pk,
    });

  const setTosNfts = (nfts: NFT[]) =>
    setAppState({
      ...appState,
      tosNfts: nfts,
    });

  return (
    <AppContext.Provider
      value={{ ...appState, setPublicKey, setTosNfts }}
      {...props}
    />
  );
};

export const useAppData: () => AppState = () => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error("useAppData must be called within an AppStateProvider");
  }

  return { ...appContext };
};
