import { AccountInfo, ParsedAccountData } from "@solana/web3.js";

export interface NftAccount {
  owner: string;
  mintAddress: string;
}

interface Attribute {
  traitType: string;
  value: any;
}

interface Creator {
  address: string;
  verified: boolean;
  share: number;
}

interface File {
  uri: string;
  type: string;
  cdn?: boolean;
}

interface Properties {
  creators?: Creator[];
  files?: File[];
  category?: string;
}

// Structure documented here
export interface NftMetadata {
  name: string;
  symbol: string;
  description: string;
  sellerFeeBasisPoints: number;
  image: string;
  externalUrl: string;
  attributes?: Attribute[];
  properties?: Properties;
}

export interface NFT {
  account: NftAccount;
  metadata: NftMetadata;
}

export const mapNftAccounts = (accInfo: AccountInfo<ParsedAccountData>) => {
  const { data } = accInfo;
  const {
    parsed: { info },
  } = data;

  return {
    owner: info.owner,
    mintAddress: info.mint,
  } as NftAccount;
};

export const mapNftMetadata = (json: any) => {
  return {
    name: json.name,
    symbol: json.symbol,
    description: json.description,
    sellerFeeBasisPoints: json.seller_fee_basis_points,
    image: json.image,
    externalUrl: json.external_url,
    attributes: json.attributes?.map(
      (attr: any) =>
        ({
          traitType: attr.trait_type,
          value: attr.value,
        } as Attribute)
    ),
    properties: {
      category: json.properties.category,
      creators: json.properties.creators?.map(
        (creator: any) =>
          ({
            address: creator.address,
            verified: creator.verified,
            share: creator.share,
          } as Creator)
      ),
      files: json.properties.files?.map(
        (file: any) =>
          ({
            uri: file.uri,
            type: file.type,
            cdn: file.cdn,
          } as File)
      ),
    },
  } as NftMetadata;
};
