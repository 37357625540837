import { useWindowWidth } from "@react-hook/window-size";
import { TowerBox } from "./components/TowerBox";
import { WalletConnector } from "./components/WalletConnector";
import { WalletViewer } from "./components/WalletViewer";
import "./App.css";

function App() {
  const width = useWindowWidth();

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-header-title">TOWER OF SOLANA</h1>
        <p>128 generative NFTs for the Solana devs out there</p>
        <p>
          The "Tower of Hanoi" is a famous puzzle, but also a popular coding
          problem used when learning the programming concept of recursion. Each
          NFT in this collection is a move in a sequence that transfers the
          rings from left to right, without placing a larger ring on top of a
          smaller one.
        </p>
        <a
          className="App-link"
          href="https://solsea.io/collection/61896e6546da3ce8f7593331"
          target="_blank"
          rel="noopener noreferrer"
        >
          Buy now on Solsea
        </a>
        <WalletConnector />
        <WalletViewer />
        <h2>Explore the Collection</h2>
        <TowerBox width={width} />
      </header>
    </div>
  );
}

export default App;
