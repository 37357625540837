import { useState } from "react";
import { SolanaRenderer, webConfig } from "../renderers";
import { Towers } from "../types";
import { getAllTowerPositions } from "../utils/towers";
import { TowerCanvas } from "./TowerCanvas";
import "./TowerBox.css";
import { getSolseaLinkFromEdition } from "../utils/solsea";

const towers: Towers = {
  towers: [
    {
      rings: [6, 5, 4, 3, 2, 1, 0],
    },
    {
      rings: [],
    },
    {
      rings: [],
    },
  ],
};

const allPositions = getAllTowerPositions(towers, 0, 2);

const solanaRenderer = new SolanaRenderer(webConfig);

interface TowerBoxProps {
  width: number;
}

const screenWidthFactor = 0.6;

export const TowerBox: React.FC<TowerBoxProps> = ({ width }) => {
  const [moveIndex, setMoveIndex] = useState(0);

  return (
    <div className="towerbox-main-panel">
      <TowerCanvas
        towerRenderer={solanaRenderer}
        towers={allPositions[moveIndex]}
        width={width * screenWidthFactor}
      />
      <div className="towerbox-bottom-panel">
        <span className="towerbox-move">Move #{moveIndex}</span>
        <a
          className="towerbox-view-link"
          href={getSolseaLinkFromEdition(moveIndex)}
          target="_blank"
          rel="noreferrer"
        >
          View this NFT on Solsea.io
        </a>
        <span>
          <button
            className="towerbox-button"
            disabled={moveIndex === 0}
            onClick={() => setMoveIndex(moveIndex - 1)}
          >
            {"<<"}
          </button>
          <button
            className="towerbox-button"
            disabled={moveIndex === allPositions.length - 1}
            onClick={() => setMoveIndex(moveIndex + 1)}
          >
            {">>"}
          </button>
        </span>
      </div>
    </div>
  );
};
