export interface Tower {
  rings: number[];
}

export interface Towers {
  towers: Tower[];
}

export interface TowerRenderer {
  drawTowers: (
    towers: Towers,
    canvasContext: CanvasRenderingContext2D,
    pixelWidth: number
  ) => void;
  getWidth: (baseWidth?: number) => number;
  getHeight: (baseWidth?: number) => number;
}

export interface SingleMove {
  fromIndex: number;
  toIndex: number;
}

export class IllegalMove extends Error {
  constructor(msg: string) {
    super(`Illegal Move: ${msg}`);
  }
}

export class IllegalSingleMove extends IllegalMove {
  constructor(move: SingleMove, msg: string) {
    super(`Illegal move: ${JSON.stringify(move)}. ${msg}`);
  }
}
