import React, { ReactNode } from "react";
import { useTosNfts } from "../../hooks/useTosNfts";
import { TosNftCard } from "./TosNftCard";
import "./WalletViewer.css";

export const WalletViewer: React.FC = () => {
  const { tosNfts } = useTosNfts();

  console.log("viewer", tosNfts);

  let content: ReactNode;
  if (tosNfts && tosNfts.length > 0) {
    content = tosNfts.map(({ account, metadata }) => (
      <TosNftCard
        key={account.mintAddress}
        mintAddr={account.mintAddress}
        name={metadata.name}
        image={metadata.image}
      />
    ));
  } else {
    const msg = tosNfts ? "Empty" : "Not Connected";
    content = <span className="wallet-viewer-message">{msg}</span>;
  }

  return (
    <div className="wallet-viewer">
      <div className="wallet-viewer-label">Your Wallet</div>
      <div className="wallet-viewer-cards">{content}</div>
    </div>
  );
};
