import { nftAddresses } from "../nft-addresses";

export const getSolseaLink = (mintAddr: string) => {
  return `https://solsea.io/nft/${mintAddr}`;
};

export const getSolseaLinkFromEdition = (edition: number) => {
  const nftAddress = nftAddresses[edition];
  return getSolseaLink(nftAddress);
};
