import { useEffect, useRef } from "react";
import { TowerRenderer, Towers } from "../types";
import { useFontLoaded } from "../hooks/useFontLoaded";

interface TowerCanvasProps {
  towerRenderer: TowerRenderer;
  towers: Towers;
  width: number;
}

export const TowerCanvas: React.FC<TowerCanvasProps> = ({
  towers,
  towerRenderer,
  width,
}) => {
  const fontsLoaded = useFontLoaded();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas && fontsLoaded) {
      const context = canvas.getContext("2d");
      if (!context) {
        throw new Error("Cannot create 2D Canvas Context");
      }

      towerRenderer.drawTowers(towers, context, width);
    }
  }, [canvasRef, towers, towerRenderer, width, fontsLoaded]);

  if (!fontsLoaded) {
    // Wait for font to load
    return null;
  }

  return (
    <canvas
      ref={canvasRef}
      width={towerRenderer.getWidth(width)}
      height={towerRenderer.getHeight(width)}
    />
  );
};
