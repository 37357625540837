import { ReactNode } from "react";
import { clipEllipsis } from "../../utils/string";
import { usePhantom } from "../../hooks/usePhantom";
import { useTosNfts } from "../../hooks/useTosNfts";
import "./WalletConnector.css";

export const WalletConnector: React.FC = () => {
  const {
    connect: connectToPhantom,
    isConnected,
    isConnecting,
    walletData,
    isError,
  } = usePhantom();
  const {
    tosNfts,
    isLoading: isLoadingNfts,
    isError: errorLoadingNfts,
  } = useTosNfts();

  const { publicKey } = walletData;
  let buttonText: string;
  if (isConnected) {
    if (publicKey) {
      buttonText =
        "Connected to: " + publicKey ? clipEllipsis(publicKey, 16) : "";
    } else {
      buttonText = "no key";
    }
  } else if (isConnecting) {
    buttonText = "Connecting...";
  } else if (isError) {
    buttonText = "Connection Error - Retry";
  } else {
    buttonText = "Connect Wallet";
  }

  const disableButton = isConnected || isConnecting;

  let nftCountMsg: ReactNode;
  let msg: string | undefined;
  if (isLoadingNfts) {
    msg = "Loading NFTs...";
  } else if (errorLoadingNfts) {
    msg = "Error loading NFTs 😭";
  } else if (tosNfts && tosNfts.length === 0) {
    msg = "You don't have any TOS NFTs 😔";
  } else if (tosNfts) {
    const plural = tosNfts.length === 1 ? "" : "s";
    msg = `You have ${tosNfts.length} TOS NFT${plural}! Thanks!`;
  }
  nftCountMsg = msg && <span className="wallet-connect-nft-count">{msg}</span>;

  return (
    <div className="wallet-connect-container">
      {!isConnected && (
        <button
          className="wallet-connect-button"
          onClick={() => {
            connectToPhantom();
          }}
          disabled={isConnected || isConnecting}
          style={{ cursor: disableButton ? "default" : "cursor" }}
        >
          {buttonText}
        </button>
      )}
      {nftCountMsg}
    </div>
  );
};
