/**
 * Connects to the phantom wallet browser extension
 * https://docs.phantom.app/integrating/establishing-a-connection
 * @returns the solana public key
 */
export const connectToPhantom = async () => {
  // @ts-ignore
  const resp = await window.solana.connect();

  const pubKey: string = resp.publicKey.toString();

  return pubKey;
};
