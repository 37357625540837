import { AccountInfo } from "@solana/web3.js";
import { deserializeUnchecked } from "borsh";
import { METADATA_SCHEMA, Metadata } from "./schema";

export const deserializeMetaplex = (acc: AccountInfo<Buffer>) => {
  const { data: buffer } = acc;

  const metadata = deserializeUnchecked(METADATA_SCHEMA, Metadata, buffer);

  return metadata;
};
