import ky from "ky";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import {
  AccountInfo,
  clusterApiUrl,
  Connection,
  ParsedAccountData,
} from "@solana/web3.js";
import { programIds } from "./utils/programIds";
import { findProgramAddress, toPublicKey } from "./utils";
import { nftAddresses } from "../../nft-addresses";
import { deserializeMetaplex } from "./utils/deserialize";
import { NftAccount, mapNftMetadata, NFT } from "./types";

// devnet,testnet,mainnet-beta
const connection = new Connection(clusterApiUrl("mainnet-beta"), "confirmed");

const getAccounts = async (pubKey: string) => {
  const accounts = await connection.getParsedProgramAccounts(TOKEN_PROGRAM_ID, {
    filters: [
      {
        dataSize: 165, // number of bytes
      },
      {
        memcmp: {
          offset: 32, // number of bytes
          bytes: pubKey, // base58 encoded string
        },
      },
    ],
  });

  return accounts.map((acc) => acc.account as AccountInfo<ParsedAccountData>);
};

const filterNftAccounts = (accounts: AccountInfo<ParsedAccountData>[]) => {
  const isNftAccount = (data: ParsedAccountData) => {
    // Logic read about here: https://gist.github.com/creativedrewy/9bce794ff278aae23b64e6dc8f10e906#step-2-locate-nft-accounts

    const { parsed } = data;
    if (!parsed) {
      // Should always be true because we called getParsedProgramAccounts
      return false;
    }

    const { info, type } = parsed;
    if (!info || type !== "account") {
      // Should always be true because we called getParsedProgramAccounts
      return false;
    }

    const { tokenAmount } = info;
    if (!tokenAmount) {
      // Not an NFT
      return false;
    }

    const { amount, decimals } = tokenAmount;

    return amount === "1" && decimals === 0;
  };

  return accounts.filter((acc) => isNftAccount(acc.data));
};

const mapNftAccounts = (accInfo: AccountInfo<ParsedAccountData>) => {
  const { data } = accInfo;
  const {
    parsed: { info },
  } = data;

  return {
    owner: info.owner,
    mintAddress: info.mint,
  } as NftAccount;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getProgramDerivedAddress = async (nft: NftAccount) => {
  const metadataProgramId = programIds().metadata;

  const programAddress = await findProgramAddress(
    [
      Buffer.from("metadata"),
      toPublicKey(metadataProgramId).toBuffer(),
      toPublicKey(nft.mintAddress).toBuffer(),
    ],
    toPublicKey(metadataProgramId)
  );

  return programAddress;
};

const filterTowerOfSolanaNfts = (nftAccounts: NftAccount[]) => {
  const nftAccSet = new Set(Object.values(nftAddresses));

  // nftAccSet.add("p2ExDWvfdfGtEzFw78GPFjaGgDCfJgWvxGNcNfaC47j"); // Solpunk
  // nftAccSet.add("4vpkMx3J2ZDW3K8d7sWg9yusvSUnXtL36UdXvx7cYY6v"); // Degen ape

  const tosNfts = nftAccounts.filter((acc) => nftAccSet.has(acc.mintAddress));

  // TODO Dev testing
  // tosNfts.push({
  //   owner: "APRTPD3mJpMw9ree3WuZ9unPRzPF8hQAtEbwcfZUHBuH",
  //   mintAddress: nftAddresses[4],
  // });
  // tosNfts.push({
  //   owner: "APRTPD3mJpMw9ree3WuZ9unPRzPF8hQAtEbwcfZUHBuH",
  //   mintAddress: nftAddresses[30],
  // });

  return tosNfts;
};

const getAccountInfo = async (pdaAddress: string) => {
  const accInfo = await connection.getAccountInfo(toPublicKey(pdaAddress));

  return accInfo;
};

const getTokenMetadata: (account: NftAccount) => Promise<NFT> = async (
  account: NftAccount
) => {
  const [pda] = await getProgramDerivedAddress(account);
  const pdaAccount = await getAccountInfo(pda);
  if (!pdaAccount) {
    throw new Error("Error occured looking up NFT metadata");
  }

  // Deserialize to
  const metadataAccount = deserializeMetaplex(pdaAccount);

  // Fetch metadata
  const { uri } = metadataAccount.data;
  const metaDataJson = await ky.get(uri).then((res) => res.json());

  // Map to metadata
  const metadata = mapNftMetadata(metaDataJson);

  return {
    account,
    metadata,
  };
};

export const getTowerOfSolanaNfts = async (pubKey: string) => {
  const accounts = await getAccounts(pubKey);
  const nftAccounts = filterNftAccounts(accounts).map(mapNftAccounts);
  const tosNftAccounts = filterTowerOfSolanaNfts(nftAccounts);

  const nfts = await Promise.all(tosNftAccounts.map(getTokenMetadata));

  return nfts;
};
